import { Editor } from "@tiptap/react";
import { motion } from "framer-motion";
import { FC, SetStateAction, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useQueryClient } from "react-query";
import { toast } from "sonner";
import { TodoIcon } from "../../../../assets/svg/todo.svg";
import { ReflectAttributes } from "../../../../text-editor/extensions/reflect-extension/extension";
import { findReflectionById } from "../../../../text-editor/extensions/reflect-extension/helper";
import { cn } from "../../../../utils/utils";
import { Button } from "../../../Button";
import { Dialog, DialogContent } from "../../../Dialog";
import { NotFound } from "../../../NotFound";
import { Pagination } from "./Pagination";
import { ViewRevision } from "./ViewRevision";

const maximumNoOfCards = 3;
const CARD_OFFSET = 20;
const SCALE_FACTOR = 0.15;

function shuffle<T>(array: T[]): T[] {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
export const ReflectCardsDialog: FC<{
  reflections: ReflectAttributes[];
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  editor: Editor;
}> = ({ reflections, open, setOpen, editor }) => {
  const [activeCards, setActiveCards] = useState<{
    index: number;
    data: ReflectAttributes[];
    animationDir: "left" | "right";
  }>(getInitialReflectCardsState(reflections));

  useHotkeys("N", () => handleNext());
  useHotkeys("P", () => handlePrevious());

  const handleNext = () => {
    const activeIndex = activeCards.index;

    if (activeIndex + 1 > reflections.length - 1) {
      return;
    }

    const newIndex = activeIndex + 1;

    setActiveCards({
      index: newIndex,
      data: reflections.slice(newIndex, newIndex + maximumNoOfCards),
      animationDir: "left",
    });
  };

  const handlePrevious = () => {
    const activeIndex = activeCards.index;

    if (activeIndex - 1 < 0) {
      return;
    }

    const newIndex = activeIndex - 1;

    setActiveCards({
      index: newIndex,
      data: reflections.slice(newIndex, newIndex + maximumNoOfCards),
      animationDir: "right",
    });
  };

  const queryClient = useQueryClient();

  const handleShowReflection = (index: number) => {
    if (index < 0 || index > reflections.length) {
      return;
    }

    const newReflections = reflections.slice(index, index + maximumNoOfCards);

    setActiveCards({
      index: index,
      data: newReflections,
      animationDir: index < activeCards.index ? "left" : "right",
    });
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
      }}
      modal
    >
      <DialogContent className="max-w-[90vw] top-[20%] md:top-[32%]  md:max-w-[575px] outline-none focus-visible:outline-none !bg-transparent shadow-none border-none [&>button]:hidden animate-in duration-500">
        <ul>
          {shuffle(reflections)
            .slice(activeCards.index, activeCards.index + maximumNoOfCards)
            .map((reflection, index) => {
              const actualData = reflections?.find(
                (refl) => refl.reflectId === reflection.reflectId
              );

              const isCurrent = reflection.reflectId === actualData.reflectId;
              const canDrag = index === 0;
              const isActive = isCurrent && canDrag;

              const brightness = 1 - index * 0.01;

              return (
                <motion.li
                  key={`${reflection.reflectId}`}
                  className={cn(
                    "absolute ml-4 backdrop-blur-lg md:ml-8 min-h-[275px] p-0 gap-4 border transition-opacity duration-700 bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark shadow-lg data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] rounded-lg md:w-full",
                    isActive ? "[&>div]:opacity-100" : "[&>div]:opacity-20",
                    "list-none"
                  )}
                  animate={{
                    top: index * -CARD_OFFSET,
                    scale: 1 - index * SCALE_FACTOR,
                    zIndex: 5000 - index * 5,
                    filter: `brightness(${brightness})`,
                  }}
                  transition={{
                    duration: 0.8,
                  }}
                  style={{
                    top: index * -24,
                    width: `${90 - index * 3}%`,
                    left: index * 8,
                    transformOrigin: "top center",
                    cursor: canDrag ? "grab" : "auto",
                  }}
                  drag={canDrag ? "y" : false}
                  dragConstraints={{
                    top: 0,
                    bottom: 0,
                  }}
                >
                  <div className="flex flex-col">
                    <div className="flex flex-row items-center justify-between p-6">
                      <div className="flex flex-row flex-wrap items-center gap-2">
                        <div className="flex flex-row gap-2 bg-[var(--hl-yellow)] size-fit p-2 rounded-md">
                          <TodoIcon className="size-4 ease-out stroke-[3]" />
                        </div>
                      </div>
                    </div>
                    {!actualData.data && (
                      <div className="pb-12">
                        <NotFound
                          logo={false}
                          header={
                            <div className="flex flex-col gap-2 items-center justify-center">
                              Unable to show reflection
                              <span className="text-sm font-normal w-64 text-textColors-muted-light dark:text-textColors-muted-dark">
                                You can safely this delete and create a new one.
                              </span>
                            </div>
                          }
                          action={
                            <div className="flex flex-row items-center gap-2 justify-center mt-4">
                              <Button
                                variant="ghost"
                                onClick={() => {
                                  setOpen(false);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="outline"
                                onClick={() => {
                                  const refl = findReflectionById(
                                    editor,
                                    actualData.reflectId
                                  );

                                  if (refl) {
                                    const reflectNode = refl.at(0)!;

                                    editor
                                      .chain()
                                      .focus()
                                      .deleteRange({
                                        from: reflectNode.pos,
                                        to:
                                          reflectNode.pos +
                                          reflectNode.node.nodeSize,
                                      })
                                      .run();

                                    toast.info("Deleted");

                                    queryClient.invalidateQueries([
                                      "reflections",
                                    ]);

                                    setOpen(false);
                                  }
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          }
                        />
                      </div>
                    )}
                    {actualData.data.type === "revision" && (
                      <ViewRevision
                        reflectionData={{
                          reflectId: actualData.reflectId,
                          data: actualData.data,
                        }}
                        isCurrent={isActive}
                        key={actualData.reflectId}
                        onClose={() => setOpen(false)}
                        editor={editor}
                        renderPagination={
                          <Pagination
                            totalLength={reflections.length}
                            activeIndex={activeCards.index}
                            onNavigate={(index) => handleShowReflection(index)}
                          />
                        }
                      />
                    )}
                  </div>
                </motion.li>
              );
            })}
        </ul>
      </DialogContent>
    </Dialog>
  );
};

const getInitialReflectCardsState = (
  reflections: ReflectAttributes[]
): {
  index: number;
  data: ReflectAttributes[];
  animationDir: "left" | "right";
} => {
  if (reflections?.length === 0 || !reflections) {
    return {
      index: -1,
      data: [],
      animationDir: null,
    };
  }

  return {
    index: 0,
    data: reflections.slice(0, maximumNoOfCards),
    animationDir: null,
  };
};
