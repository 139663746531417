import { Editor } from "@tiptap/react";
import { AnimatePresence, motion } from "framer-motion";
import { ArrowDown, ArrowRightLeft, ArrowUp, Check, Trash } from "lucide-react";
import { FC, ReactNode, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useQueryClient } from "react-query";
import { toast } from "sonner";
import { ConfidenceSvg } from "../../../../assets/svg/confidence.svg";
import { ReflectAttributes } from "../../../../text-editor/extensions/reflect-extension/extension";
import {
  findReflectionById,
  getConfidenceScore,
  handleUpdateForgottenReflectAttributes,
  handleUpdateRetainedReflectAttributes,
  resetConfidenceReflectAttributes,
} from "../../../../text-editor/extensions/reflect-extension/helper";
import { cn } from "../../../../utils/utils";
import { Button } from "../../../Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../../DropdownMenu";
import { LucideIcon } from "../../../LucideIcon";
import { Separator } from "../../../Separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../Tooltip";
import { ViewAnswer } from "./ViewAnswer";

export const ViewRevision: FC<{
  reflectionData: {
    reflectId: ReflectAttributes["reflectId"];
    data: Exclude<ReflectAttributes["data"], { type: "summary" }>;
  };
  editor: Editor;
  onClose: () => void;
  isCurrent: boolean;
  renderPagination: ReactNode;
}> = ({ reflectionData, editor, renderPagination, onClose, isCurrent }) => {
  const [revealAnswer, setRevealAnswer] = useState(false);
  const [openConfidence, setOpenConfidence] = useState(false);

  const question = Object.keys(reflectionData.data?.data)?.[0];
  const answer = reflectionData?.data.data?.[question]?.answer;

  const retainedCount =
    parseInt(reflectionData?.data.data?.[question]?.retained) || 0;
  const forgottenCount =
    parseInt(reflectionData?.data.data?.[question]?.forgotten) || 0;

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleUpdateRetained = () => {
    if (isCurrent) {
      handleUpdateRetainedReflectAttributes({
        reflectId: reflectionData.reflectId,
        newRetainedCount: retainedCount + 1,
        editor,
        reflectionData,
      });

      // update reflections manually
      queryClient.invalidateQueries(["reflections"]);
      setOpenConfidence(false);
    }
  };

  const handleUpdateForgotten = () => {
    if (isCurrent) {
      // update forgotten
      handleUpdateForgottenReflectAttributes({
        reflectId: reflectionData.reflectId,
        newForgottenCount: forgottenCount + 1,
        editor,
        reflectionData,
      });

      // update reflections manually
      queryClient.invalidateQueries(["reflections"]);

      setOpenConfidence(false);
    }
  };

  useHotkeys("T", () => setRevealAnswer(!revealAnswer));
  useHotkeys("R", () => handleUpdateRetained(), [isCurrent]);
  useHotkeys("M", () => handleUpdateForgotten(), [isCurrent]);

  const queryClient = useQueryClient();

  const handleDelete = (reflectId: string) => {
    const reflection = findReflectionById(editor, reflectId);

    if (reflection) {
      const reflectNode = reflection.at(0)!;

      editor
        .chain()
        .focus()
        .deleteRange({
          from: reflectNode.pos,
          to: reflectNode.pos + reflectNode.node.nodeSize,
        })
        .run();

      toast.info("Deleted");

      queryClient.refetchQueries(["reflections"]);

      onClose();
    }
  };

  return (
    <div className="flex flex-col gap-1 px-6 pr-1 pb-12 max-h-[280px] overflow-auto">
      <span className={cn("text-xl shrink-0")}>{question}</span>
      <Separator className="w-4 mt-2" />
      <div className={cn("pr-7")}>
        <AnimatePresence>
          {revealAnswer && (
            <motion.div
              className={cn("pt-1 pb-8")}
              initial={{
                height: "auto",
              }}
              animate={
                answer.startsWith(`{"type":"doc",`)
                  ? false
                  : revealAnswer
                    ? { height: "auto" }
                    : { height: "32px" }
              }
              transition={{
                duration: 1,
                ease: "easeInOut",
              }}
            >
              <ViewAnswer answer={answer} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div className="absolute top-5 right-3">
        <DropdownMenu open={openConfidence} onOpenChange={setOpenConfidence}>
          <DropdownMenuTrigger asChild>
            <Button className="gap-0.5 w-fit h-7" variant="ghost">
              <ConfidenceSvg
                className={cn(
                  "size-4 stroke-[9] mt-[3px]",
                  retainedCount >= forgottenCount
                    ? "stroke-[var(--green)]"
                    : "stroke-[var(--red)]"
                )}
              />
              {!isNaN(retainedCount / forgottenCount) && (
                <span className="text-sm transition-opacity duration-500">
                  {getConfidenceScore(retainedCount, forgottenCount)}%
                </span>
              )}
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="p-0">
            <div className="flex flex-col gap-2 max-w-48">
              <div className="flex flex-col gap-1 pt-3">
                <div className="flex flex-row justify-between px-3">
                  <span className="text-sm">Confidence</span>
                  {isNaN(retainedCount / forgottenCount) ? (
                    <span className="text-sm text-textColors-muted-light dark:text-textColors-muted-dark">
                      --
                    </span>
                  ) : (
                    <span className="text-sm transition-all duration-500 size-4 w-8 text-right">
                      {getConfidenceScore(retainedCount, forgottenCount)}%
                    </span>
                  )}
                </div>
                <p className="px-3 pb-3 text-xs text-textColors-muted-light dark:text-textColors-muted-dark">
                  Confidence, in this system, reflects how well information is
                  retained.
                </p>
                <Separator />
                <div className="px-3 py-2 text-xs text-textColors-muted-light dark:text-textColors-muted-dark flex flex-col gap-1">
                  <div className="flex flex-row items-center gap-1">
                    <span className="border size-4 text-xs grid place-items-center rounded border-border-primary-light dark:border-border-primary-dark">
                      R
                    </span>
                    <span className="text-xs">to mark as retained</span>
                    <LucideIcon
                      icon={ArrowUp}
                      className="stroke-[var(--green)]"
                    />
                  </div>
                  <div className="flex flex-row items-center gap-1">
                    <span className="border size-4 text-xs grid place-items-center rounded border-border-primary-light dark:border-border-primary-dark">
                      M
                    </span>
                    <span className="text-xs">to mark as missed</span>
                    <LucideIcon
                      icon={ArrowDown}
                      className="stroke-[var(--red)]"
                    />
                  </div>
                </div>
              </div>
              <Separator className="w-full" />
              <div className="px-3 pb-3 flex flex-row items-center justify-between">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => handleUpdateRetained()}
                  className="w-fit leading-6 gap-1 h-5 px-2 !text-[11px]"
                >
                  <span>{retainedCount}</span>
                  <LucideIcon
                    icon={ArrowUp}
                    className="stroke-[var(--green)]"
                  />
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    // reset values
                    resetConfidenceReflectAttributes({
                      reflectId: reflectionData.reflectId,
                      editor,
                      reflectionData,
                    });

                    queryClient.invalidateQueries(["reflections"]);

                    setOpenConfidence(false);
                  }}
                  className="w-fit leading-6 gap-1 h-5 px-2 !text-[11px]"
                >
                  Reset
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => handleUpdateForgotten()}
                  className="w-fit leading-6 gap-1 h-5 px-2 !text-[11px]"
                >
                  <span>{forgottenCount}</span>
                  <LucideIcon
                    icon={ArrowDown}
                    className="stroke-[var(--red)]"
                  />
                </Button>
              </div>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="absolute rounded-b-lg bottom-0 left-0 flex flex-row items-center justify-between w-full p-4 bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark">
        <div className="flex flex-row items-center gap-2">
          <TooltipProvider>
            <Tooltip defaultOpen={false} delayDuration={1000}>
              <TooltipTrigger asChild onFocus={(e) => e.preventDefault()}>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setRevealAnswer(!revealAnswer)}
                  className="w-fit leading-6 gap-1 h-5 px-2 !text-[11px]"
                >
                  <LucideIcon icon={ArrowRightLeft} className="size-2.5" />
                  Reveal
                </Button>
              </TooltipTrigger>
              <TooltipContent
                className={cn(
                  "flex flex-row gap-2 items-center bg-backgroundColors-background-light dark:bg-backgroundColors-background-dark",
                  "text-textColors-background-light dark:text-textColors-background-dark",
                  "border border-border-primary-light/80 dark:border-border-primary-dark/80"
                )}
              >
                <div className="flex flex-row gap-2 items-center">
                  <span className="border size-4 text-xs grid place-items-center rounded border-border-primary-light dark:border-border-primary-dark">
                    T
                  </span>
                  <span className="text-xs">to reveal insight</span>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <div className="flex flex-row items-center">
            <Button
              size="icon"
              className="size-5 gap-4"
              variant="outline"
              onClick={() => setShowDeleteConfirmation(!showDeleteConfirmation)}
            >
              <LucideIcon icon={Trash} className="size-3" />
            </Button>
            {showDeleteConfirmation && (
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                transition={{
                  duration: 0.5,
                  ease: "easeOut",
                }}
                className="flex ml-2 flex-row items-center border border-border-primary-light size-5 w-fit dark:border-border-primary-dark gap-6 bg-backgroundColors-gray-light dark:bg-backgroundColors-gray-dark rounded-md"
              >
                <Button
                  className="w-fit px-1 text-xs"
                  variant="hoverLink"
                  onClick={() => {
                    handleDelete(reflectionData.reflectId);
                  }}
                >
                  <LucideIcon
                    icon={Check}
                    className="size-3"
                    onDoubleClick={() =>
                      setShowDeleteConfirmation(!showDeleteConfirmation)
                    }
                  />
                  Confirm
                </Button>
              </motion.div>
            )}
          </div>
        </div>
        {renderPagination}
      </div>
    </div>
  );
};
